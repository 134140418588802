import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AuthenService } from '@app/services/authen.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthenGuard implements CanActivate, CanActivateChild {

  constructor(
    private router: Router,
    private authenService: AuthenService
  ) { }

  canActivate(route: ActivatedRouteSnapshot,state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    // this.router.navigate(['/sign-in']);
    // console.log('canActivate data', route.data);
    if(this.authenService.getAuthenToken()){
      return true;
    }
    this.router.navigate(['/login']);
    return false;
  }
  canActivateChild(childRoute: ActivatedRouteSnapshot,state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if(this.authenService.getAuthenToken()){
      return true;
    }
    this.router.navigate(['/login']);
    return false;
  }
  
}
