import { Component, OnInit, AfterViewInit} from '@angular/core';
import { Router } from '@angular/router';
import * as $ from 'jquery';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit{
 
  title = 'luihub-admin';
  // menu
  openMap = {
    submenuUsers: false,
    submenuDashboard: false,
    submenuJobReq: false,
    submenuSetting: false,
  };

  inLayout = true

  constructor(
    protected route: Router,
  ) {
    // console.log('location.pathname constructor', location.pathname)
    if (location.pathname.includes('/job-request/detail/') || location.pathname.includes('/login')) {
      this.inLayout = false;
    }
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.openActive()
    }, 150);
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.openActive()
    }, 150);
  }

  openActive(){
    let activeclass:any = $('.ant-menu-submenu-open.ant-menu-submenu-selected')
    console.log('activeclass---',activeclass)

    for(let menu of activeclass){
      console.log('menu---',menu)
      console.log('submenu---',menu.dataset['submenu'])
      try {
        for (const key in this.openMap) {
          if (key !== menu.dataset['submenu']) {
            this.openMap[key] = false;
          }
        }
        this.openMap[menu.dataset['submenu']] = true;
      } catch (error) {
        
      }
    }
  }

  openHandler(value: string): void {
    // console.log('nzOpenChange value-----',value)
    for (const key in this.openMap) {
      if (key !== value) {
        this.openMap[key] = false;
      }
    }
  }

}
