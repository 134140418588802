import { Component, OnInit, AfterViewInit} from '@angular/core';
import { Router } from '@angular/router';
import { AuthenService } from '@app/services/authen.service';
import { IAuthorization } from '@app/types/IAuthorization';
import * as $ from 'jquery';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-app-layout',
  templateUrl: './app-layout.component.html',
  styleUrls: ['./app-layout.component.scss']
})
export class AppLayoutComponent implements OnInit, AfterViewInit{
 
  title = 'luihub-admin';
  // menu
  openMap = {
    submenuUsers: false,
    submenuDashboard: false,
    submenuJobReq: false,
    submenuSetting: false,
  };
  profile: IAuthorization.Profile = null
  inLayout = true

  constructor(
    protected route: Router,
    private authenService: AuthenService
  ) {}

  ngAfterViewInit() {
    setTimeout(() => {
      this.openActive()
    }, 150);
  }

  async ngOnInit(): Promise<void> {
    setTimeout(() => {
      this.openActive()
    }, 150);
    this.profile = await lastValueFrom(this.authenService.profile())
  }

  onSignOut(){
    localStorage.removeItem(this.authenService.KEY_NAME)
    location.reload()
  }

  openActive(){
    let activeclass:any = $('.ant-menu-submenu-open.ant-menu-submenu-selected')
    // console.log('activeclass---',activeclass)

    for(let menu of activeclass){
      // console.log('menu---',menu)
      // console.log('submenu---',menu.dataset['submenu'])
      try {
        for (const key in this.openMap) {
          if (key !== menu.dataset['submenu']) {
            this.openMap[key] = false;
          }
        }
        this.openMap[menu.dataset['submenu']] = true;
      } catch (error) {
        
      }
    }
  }

  openHandler(value: string): void {
    // console.log('nzOpenChange value-----',value)
    for (const key in this.openMap) {
      if (key !== value) {
        this.openMap[key] = false;
      }
    }
  }

}
