<nz-layout style="width: 100vw; height: 100vh;">
    <nz-header>
        <div class="row mx-0 h-100 justify-content-between">
            <div class="col-auto px-0">
                <div style="width: 200px;" class="d-flex h-100">
                    <img src="/assets/images/logo/logo-luihub-primary.svg" height="60" class="m-auto">
                </div>
            </div>
            <div class="col-auto">
                <button nz-button nz-dropdown [nzDropdownMenu]="menu" nzType="text">
                    <span>
                        <div class="row mx-0">
                            <div class="col-auto ps-0 pe-2 align-self-center">
                                <nz-avatar class="mr-1" nzIcon="user" [nzSize]="18"
                                   *ngIf="!profile?.picture" [nzSrc]="'https://avatars.dicebear.com/v2/identicon/admin.svg'"></nz-avatar>
                                <nz-avatar class="mr-1" nzIcon="user" [nzSize]="18"
                                   *ngIf="profile?.picture" [nzSrc]="profile?.picture"></nz-avatar>
                            </div>
                            <div class="col-auto px-0 align-self-center">
                                <span class="me-2">{{profile?.name}} {{profile?.lastname}}</span>
                                <i nz-icon nzType="down"></i>
                            </div>
                        </div>
                    </span>
                </button>
                <nz-dropdown-menu #menu="nzDropdownMenu">
                    <ul nz-menu nzSelectable>
                        <li nz-menu-item routerLink="/profile">โปรไฟล์</li>
                        <li nz-menu-item nzDanger (click)="onSignOut()">ออกจากระบบ</li>
                    </ul>
                </nz-dropdown-menu>
            </div>
        </div>
    </nz-header>
    <nz-layout>
        <nz-sider nzWidth="200px">

            <ul nz-menu nzMode="inline">

                <li nz-submenu nzTitle="Dashboard" nzIcon="bar-chart"
                    routerLinkActive="ant-menu-submenu-open ant-menu-submenu-selected"
                    [attr.data-submenu]="'submenuDashboard'" [nzOpen]="openMap.submenuDashboard"
                    (nzOpenChange)="openHandler('submenuDashboard')">
                    <ul>
                        <li nz-menu-item routerLink="report-website-activities"
                            routerLinkActive="ant-menu-item-selected">ข้อมูลเข้าใช้งาน</li>
                        <li nz-menu-item routerLink="report-search" routerLinkActive="ant-menu-item-selected">
                            ข้อมูลการค้นหา</li>
                        <li nz-menu-item routerLink="report-jobs" routerLinkActive="ant-menu-item-selected">
                            ข้อมูลลงประกาศงาน</li>
                        <li nz-menu-item routerLink="report-employment" routerLinkActive="ant-menu-item-selected">
                            ข้อมูลการจ้างงาน</li>
                        <li nz-menu-item routerLink="report-status" routerLinkActive="ant-menu-item-selected">
                            ข้อมูลสถานะ</li>
                    </ul>
                </li>

                <li nz-menu-item routerLink="job-lists" routerLinkActive="ant-menu-item-selected">
                    <i nz-icon nzType="unordered-list" nzTheme="outline"></i>
                    <span>Job Lists</span>
                </li>

                <li nz-submenu nzTitle="Job Requests" nzIcon="folder-open"
                    routerLinkActive="ant-menu-submenu-open ant-menu-submenu-selected"
                    [attr.data-submenu]="'submenuJobReq'" [nzOpen]="openMap.submenuJobReq"
                    (nzOpenChange)="openHandler('submenuJobReq')">
                    <ul>
                        <li nz-menu-item routerLink="job-requests" routerLinkActive="ant-menu-item-selected">รายการ</li>
                        <li nz-menu-item routerLink="history-logs" routerLinkActive="ant-menu-item-selected">
                            ประวัติการทำรายการ</li>
                    </ul>
                </li>

                <li nz-submenu nzTitle="User" nzIcon="user"
                    routerLinkActive="ant-menu-submenu-open ant-menu-submenu-selected"
                    [attr.data-submenu]="'submenuUsers'" [nzOpen]="openMap.submenuUsers"
                    (nzOpenChange)="openHandler('submenuUsers')">
                    <ul>
                        <li nz-menu-item routerLink="company-lists" routerLinkActive="ant-menu-item-selected">
                            รายชื่อบริษัท
                        </li>
                        <li nz-menu-item routerLink="jobseeker-lists" routerLinkActive="ant-menu-item-selected">
                            รายชื่อผู้หางาน</li>
                        <li nz-menu-item routerLink="teams" routerLinkActive="ant-menu-item-selected">Teams</li>
                    </ul>
                </li>
                <li nz-menu-item routerLink="otp-logs" routerLinkActive="ant-menu-item-selected">
                    <i nz-icon nzType="unordered-list" nzTheme="outline"></i>
                    <span>OTP Logs</span>
                </li>
                <li nz-submenu nzTitle="Setting" nzIcon="setting"
                    routerLinkActive="ant-menu-submenu-open ant-menu-submenu-selected"
                    [attr.data-submenu]="'submenuSetting'" [nzOpen]="openMap.submenuSetting"
                    (nzOpenChange)="openHandler('submenuSetting')">
                    <ul>
                        <li nz-menu-item routerLink="general" routerLinkActive="ant-menu-item-selected">ทั่วไป
                        </li>
                        <li nz-menu-item routerLink="notifications" routerLinkActive="ant-menu-item-selected">แจ้งเตือน
                        </li>
                        <li nz-menu-item routerLink="profile" routerLinkActive="ant-menu-item-selected">โปรไฟล์</li>
                    </ul>
                </li>
            </ul>
        </nz-sider>
        <nz-layout>
            <nz-content class="inner-layout">
                <router-outlet></router-outlet>
            </nz-content>
        </nz-layout>
    </nz-layout>
</nz-layout>