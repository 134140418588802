import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppLayoutComponent } from './app-layout/app-layout.component';
import { AuthenGuard } from './guards/authen/authen.guard';

const routes: Routes = [
  
  { path: 'login', loadChildren: () => import('@app/login/login.module').then(m => m.LoginModule) },
  { canActivate: [AuthenGuard], canActivateChild: [AuthenGuard], path: 'job-request/detail/:schedule_no', loadChildren: () => import('@app/job-request-detail/job-request-detail.module').then(m => m.JobRequestDetailModule) },
  { path: '', component: AppLayoutComponent, canActivate: [AuthenGuard], canActivateChild: [AuthenGuard], children: [
    { path: '', redirectTo: 'job-requests', pathMatch: 'full' },
    { path: 'report-website-activities', loadChildren: () => import('@app/report-website-activities/report-website-activities.module').then(m => m.ReportWebsiteActivitiesModule) },
    { path: 'report-search', loadChildren: () => import('@app/report-search/report-search.module').then(m => m.ReportSearchModule) },
    { path: 'report-jobs', loadChildren: () => import('@app/report-jobs/report-jobs.module').then(m => m.ReportJobsModule) },
    { path: 'report-employment', loadChildren: () => import('@app/report-employment/report-employment.module').then(m => m.ReportEmploymentModule) },
    { path: 'report-status', loadChildren: () => import('@app/report-status/report-status.module').then(m => m.ReportStatusModule) },
    { path: 'job-lists', loadChildren: () => import('@app/job-lists/job-lists.module').then(m => m.JobListsModule) },
    { path: 'job/detail/:id', loadChildren: () => import('@app/job-detail/job-detail.module').then(m => m.JobDetailModule) },
    { path: 'job/create', loadChildren: () => import('@app/job-detail/job-detail.module').then(m => m.JobDetailModule) },
    { path: 'job-requests', loadChildren: () => import('@app/job-requests/job-requests.module').then(m => m.JobRequestsModule) },
    { path: 'history-logs', loadChildren: () => import('@app/history-logs/history-logs.module').then(m => m.HistoryLogsModule) },
    { path: 'company-lists', loadChildren: () => import('@app/company-lists/company-lists.module').then(m => m.CompanyListsModule) },
    { path: 'jobseeker-lists', loadChildren: () => import('@app/jobseeker-lists/jobseeker-lists.module').then(m => m.JobseekerListsModule) },
    { path: 'teams', loadChildren: () => import('@app/teams/teams.module').then(m => m.TeamsModule) },
    { path: 'notifications', loadChildren: () => import('@app/notifications/notifications.module').then(m => m.NotificationsModule) },
    { path: 'profile', loadChildren: () => import('@app/profile/profile.module').then(m => m.ProfileModule) },
    { path: 'general', loadChildren: () => import('@app/general/general.module').then(m => m.GeneralModule) },
    { path: 'otp-logs', loadChildren: () => import('@app/otp-logs/otp-logs.module').then(m => m.OtpLogsModule) }
  ] }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
