import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IAuthorization } from '@app/types/IAuthorization';
import { Observable } from 'rxjs';
import jwt_decode from 'jwt-decode';

@Injectable({
  providedIn: 'root'
})
export class AuthenService {

  constructor(
    private http: HttpClient,
  ) { }

  KEY_NAME = 'authorization'

  getAuthorization(): IAuthorization.ResponseToken{
    let token = localStorage.getItem(this.KEY_NAME)
    if(!token){
      return {
        token_type: 'Bearer',
        access_token: null,
        refresh_token: null,
        expires_in: null,
        is_registered: false
      }
    }
    return JSON.parse(token)
  }

  getAuthenToken(): string{
    return this.getAuthorization().access_token
  }

  getRefreshToken(): string{
    return this.getAuthorization().refresh_token
  }

  refresh(refreshToken: string): Observable<IAuthorization.ResponseToken>{
    return this.http.post('/authen/refresh', {refresh_token: refreshToken}) as Observable<IAuthorization.ResponseToken>
  }

  signIn(data: IAuthorization.SignInAdminInput): Observable<IAuthorization.ResponseToken>{
    return this.http.post('/authen/admin/sign-in', data) as Observable<IAuthorization.ResponseToken>
  }  
  
  jwtDecode(access_token: string | null): IAuthorization.AuthenDecode | null {
    if(!access_token) return null
    return jwt_decode(access_token)
  }

  profile(): Observable<IAuthorization.Profile>{
    return this.http.get('/api/v1/profile') as Observable<IAuthorization.Profile>
  }

  updateProfile(obj: IAuthorization.ProfileUpdateInput): Observable<IAuthorization.Profile>{
    return this.http.put('/api/v1/profile', obj) as Observable<IAuthorization.Profile>
  }

  changePassword(obj: IAuthorization.UpdatePasswordInput): Observable<IAuthorization.Profile>{
    return this.http.put('/api/v1/profile/changepassword', obj) as Observable<IAuthorization.Profile>
  }

  forgotpassword(data: any): Observable<any>{
    return this.http.post('/api/v1/login/resetpassword', data) as Observable<any>
  }

  searchOTPLogs(data: any): Observable<any>{
    return this.http.post('/authen/searchOTPLogs', data) as Observable<any>
  }

}
